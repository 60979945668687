@tailwind base;
@tailwind components;
@tailwind utilities;

.swal-button {
  padding: 7px 19px;
  border-radius: 8px;
  background-color: #4525f2;
  font-size: 14px;
  border: 1px solid #4525f2;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}
